
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:ital,wght@0,400;1,200&family=Questrial&family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Questrial", sans-serif;
  scroll-behavior: smooth;
  color-scheme: light;


}

body{

  background: linear-gradient(315deg, #FFF 2.09%, #DBDBDB 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  overscroll-behavior: none;

  
}


/*************** Page Contact form input animation ********************/

input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text{
  @apply text-secondary transform -translate-y-10 -translate-x-2 scale-75;
}

input[type="email"]:focus ~ .input-email,
input[type="email"]:not(:placeholder-shown) ~ .input-email{
  @apply text-secondary transform -translate-y-10 -translate-x-2 scale-75;
}

textarea[type="textarea"]:focus ~ .input-textarea,
textarea[type="textarea"]:not(:placeholder-shown) ~ .input-textarea{
  @apply text-secondary transform -translate-y-10 -translate-x-2 scale-75;
}




/************************* Page ProjectDisplay - Modal **************************/

.overview-container{

   position: fixed; 
   top:0;
   bottom:0;
   left: 0;
   right:0;
   background-color: rgb(0,0,0,0.8);
   height: auto;
   z-index: 1;
}

.overview-body{

  width : 100%;
  height : auto;
  background-color: #e0e0e0;
  margin: auto;
}

.overview-content{

  display:block;
  justify-content: center;
  align-items:center;
}

.content-video{

  width: 100%;
  height:auto;
}
.videobox{
  width: 100%;
}

.close{
    
  border: 1px solid white;
  border-radius: 10px;
  width: 50px;
  height: auto; 
  color: white;
  margin-right: 2px;
  background: #e0e0e0;
  box-shadow:  20px 20px 60px #bebebe;
  
}





@media only screen and (min-width:814px) {
.overview-body{

   width : 60%;
   height : auto;
   background-color: #e0e0e0;
   margin-top: 90px;
}
.content-car{
   
   display:flex;
   justify-content: center;
   align-items: center;
}

.details{

  width: 50%;
  margin-right: 5px;
  margin-left:5px;
}

.content-video{
    
  width: 50%;
  height:auto;
  margin-bottom: 30px;
}

.overview-content{ 
   width: 100%;
   text-align:start;
  
}

}

@media only screen and (min-width: 601px) {

  .hero{
    margin-top: 80px;
  }


 .content-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap:wrap;
     
}
 .details{
   width: 100%;
   text-align:start;
}

.content-video{
   width: 100%;
   padding-right: 5px;
   padding-left: 5px;
  

}

.overview-header{
  display:flex; 
  justify-content: flex-end;
  padding: 5px;

  }

  span .mail{
   
   margin-top: 20px;
  }
}


@media(max-width: 600px){

  .hero{
    margin-top:0px;
  }

  .container-hero{
    margin-top:0px;
  }
   
   .content-video{

     
     padding-right: 5px;
     padding-left: 5px;
     width: 100%;
     height: auto;


   }

    .content-card{
     
     display: flex;
     flex-direction: column;
     justify-content: center;
     flex-wrap:wrap;
   }

   .details{

    width: 100%;
    text-align: center;
  
   
   }
   .title-description{
    font-size: 20px;
   }

   .description{

    font-size: 16px;
   }
  
  .github{
    display:flex;
    justify-content: center;
    
  }


  .overview-header{
   
     padding: 5px;
     display:flex; 
     justify-content: flex-end;

  } 

  .overview-body{

    margin-top:80px;
  }
  

 

}






